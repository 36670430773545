import React from "react"
import styled from "styled-components"

import SEO from "../components/seo"

const Container = styled.section`
  text-align: center;
`

const MusicPage = () => (
  <Container>
    <SEO title="Music" />
    <iframe title="bandcamp-eindhoven-behind-a-mask" style={{border: 0, width: '400px', height: '520px'}} src="https://bandcamp.com/EmbeddedPlayer/album=2418456119/size=large/bgcol=ffffff/linkcol=de270f/tracklist=false/transparent=true/" seamless><a href="https://theorchestracollective.bandcamp.com/album/eindhoven-behind-the-mask">Eindhoven Behind The Mask by The Orchestra Collective</a></iframe>
    <iframe title="mixcloud-martin-krivano" width="100%" height="120" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2FMartinKrivano%2Fmartin-krivano-the-opera-2-foyer-mix%2F" frameborder="0" ></iframe>
    <iframe title="mixcloud-max-frimout" width="100%" height="120" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2FRARARADIO_EHV%2Fmax-frimont-rararadio-18-03-2020%2F" frameborder="0" ></iframe>
    <iframe title="mixcloud-rho" width="100%" height="120" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2FDJRho%2Fsesh-in-the-park-02%2F" frameborder="0" ></iframe>
    <iframe title="mixcloud-ms-take" width="100%" height="120" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&light=1&feed=%2FRARARADIO_EHV%2Fms-take-the-orchestra-rararadio-21-08-2020%2F" frameborder="0" ></iframe>
  </Container>
)

export default MusicPage
